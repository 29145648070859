import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const footerLinks = [
  { label: "Taara", link: "/taara" },
  { label: "About", link: "/about" },
  { label: "Blog", link: "/blog" },
  { label: "FAQ", link: "/faq" },
];

const Footer = () => {
  return (
    <>
      <div className="footerComponent">
        <div className="topFooterArea">
          <div className="leftTopFooter">
            <div className="footerLogo"></div>
            <div className="footerLogoText">
              Empowering women to lead with confidence and courage—discover your
              potential with Taara Quest.
            </div>
          </div>
          <div className="rightTopFooter">
            <div className="rightTopFooterList">
              <div className="rightTopFooterListHeading">QUICK LINKS</div>
              {footerLinks.map((footerLink, idx) => (
                <Link
                  className="rightTopFooterListElement"
                  to={footerLink.link}
                  key={idx}
                >
                  {footerLink.label}
                </Link>
              ))}
            </div>
            {/* <div className="rightTopFooterList">
						<div className="rightTopFooterListHeading">HELP CENTER</div>
						<div className="rightTopFooterListElement">Find on Store</div>
						<div className="rightTopFooterListElement">How to Install?</div>
						<div className="rightTopFooterListElement">Why Us?</div>
						<div className="rightTopFooterListElement">FAQs</div>
					</div> */}
            <div className="rightTopFooterList">
              <div className="rightTopFooterListHeading">CONTACT INFO</div>
              <div className="rightTopFooterListElement">hello@taara.quest</div>
              <div className="rightTopFooterListElement">
                Gohliser Str. 16, 04105 Leipzig, Germany
              </div>
              <div className="socialFooterIcons">
                <a href="https://www.tiktok.com/@taara_quest?is_from_webapp=1&sender_device=pc">
                  <i className="fa-brands fa-tiktok"></i>
                </a>
                <a href="https://www.instagram.com/taara.quest/">
                  <i className="fa-brands fa-square-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/company/taara-quest">
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <hr className="footerDivider" />
        <br></br>
        <div className="bottomFooterArea">
          © 2024 apergo UG | All rights reserved | Imprint | Privacy & Cookie
          Policy
        </div>
      </div>
      <div className="mobileFooterComponent">
        <hr />
        <div className="mobileFooterLink">Company</div>
        <div className="mobileFooterLink">Services</div>
        <div className="mobileFooterLink">Company Issue</div>
        <div className="mobileFooterIcons">
          <a href="https://www.tiktok.com/@taara_quest?is_from_webapp=1&sender_device=pc">
            <i className="fa-brands fa-tiktok"></i>
          </a>
          <a href="https://www.instagram.com/taara.quest/">
            <i className="fa-brands fa-square-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/taara-quest">
            <i className="fa-brands fa-linkedin"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
