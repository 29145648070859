import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import Landing from "./pages/Landing/Landing";
import Taara from "./pages/Taara/Taara";
import About from "./pages/About/About";
import Blog from "./pages/Blog/Blog";
import FAQ from "./pages/FAQ/FAQ";
import Ambassadors from "./pages/Ambassadors/Ambassadors";
import Assessment from "./pages/Assessment/Assessment";
import BlogDetails from "./pages/BlogDetails/BlogDetails";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/taara" element={<Taara />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/ambassadors" element={<Ambassadors />} />
        <Route path="/assessment" element={<Assessment />} />
      </Routes>
    </Router>
  );
}

export default App;
