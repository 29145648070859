import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import "./NonProtectedLayout.css"

const NonProtectedLayout = ({ children, showFooter = true }) => {
  return (
    <div className="NonProtectedLayoutContainer">
      <Navbar />
      {children}
      {showFooter && <Footer />}
    </div>
  );
};

export default NonProtectedLayout;
