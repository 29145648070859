import React from "react";
import "./FAQ.css";
import FAQAccordian from "../../components/faq/FAQAccordian/FAQAccordian";
import SpaceWrapper from "../../components/wrapper/SpaceWrapper/SpaceWrapper";
import AskTaaraForm from "../../components/AskTaaraForm/AskTaaraForm";
import faqs from "../../data/faqs.json";
import NonProtectedLayout from "../../layout/NonProtectedLayout";

const FAQ = () => {
  return (
    <NonProtectedLayout>
      <SpaceWrapper>
        <h1 className="faq-heading">WHAT THE FAQ?</h1>
        <h3 className="faq-sub-heading">Sub-Headline</h3>
        <div className="faq-container">
          <div className="faq-container-left">
            <h3 className="heading">Shortcut To Your Question</h3>
            <div className="faq-questions">
              {faqs.map((faq, idx) => (
                <div className="question" key={idx}>
                  {faq.question}
                </div>
              ))}
            </div>
          </div>
          <div className="faq-container-right">
            {faqs.map((faq, idx) => (
              <FAQAccordian
                key={idx}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
        </div>
      </SpaceWrapper>
      <AskTaaraForm />
    </NonProtectedLayout>
  );
};

export default FAQ;
