import React from "react";
import LazyImage from "../../LazyImage/LazyImage";
import "./LandingHeroImage.css";

const LandingHeroImage = ({ src, alt, heading, ctaText, onClick }) => {
  return (
    <div className="hero-container">
      <div className="heroImage">
        <LazyImage src={src} alt={alt} bg="none" />
      </div>
      <div className="overlayImage">
        <div className="overlayImageText">{heading}</div>
        <div className="overlayImageButton" onClick={onClick}>
          {ctaText}
        </div>
      </div>
    </div>
  );
};

export default LandingHeroImage;
