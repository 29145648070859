import React from "react";
import "./OurAmbassadors.css";
import LazyImage from "../../LazyImage/LazyImage";
import luise from "../../../images/about/team/luise.svg";

const ourAmbassadors = [
  { name: "Kamilla Faszylova", src: luise },
  { name: "Kamilla Faszylova", src: luise },
  { name: "Kamilla Faszylova", src: luise },
];

const OurAmbassadors = () => {
  return (
    <div className="OurAmbassadorsSection">
      <div className="OurAmbassadorsContainer">
        <div className="OurAmbassadorsLeftContent">
          <div className="AmbassadorText">Meet our</div>
          <div className="AmbassadorMainHeading">AMBASSADORS</div>
        </div>
        <div className="OurAmbassadorsRightContent">
          <p>
            Get inspired by the incredible women who are already making a
            difference as Taara Ambassadors.
          </p>
        </div>
      </div>
      <div className="AllAmbassadors">
        {ourAmbassadors?.map((profile, idx) => (
          <div className="relative w-full mobile:w-[263px] h-[458px] mobile:h-[308px]">
            <LazyImage
              src={profile.src}
              alt={profile.name}
              className="w-full h-full object-cover rounded-[20px]"
              bg="none"
            />
            <p className="absolute w-full text-center bottom-8 font-nohemi text-white text-2xl mobile:text-lg">
              {profile.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurAmbassadors;
