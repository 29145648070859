import React from "react";
import "./Ambassadors.css";
import NonProtectedLayout from "../../layout/NonProtectedLayout";
import LandingHeroImage from "../../components/ambassadors/LandingHeroImage/LandingHeroImage";
import Support from "../../components/ambassadors/Support/Support";
import EventCards from "../../components/ambassadors/EventCards/EventCards";
import TaaraAmbassador from "../../components/ambassadors/TaaraAmbassador/TaaraAmbassador";
import PromiseYouEverything from "../../components/ambassadors/PromiseYouEverything/PromiseYouEverything";
import HowToJoin from "../../components/ambassadors/HowToJoin/HowToJoin";
import OurAmbassadors from "../../components/ambassadors/OurAmbassadors/OurAmbassadors";
import Doing from "../../components/ambassadors/Doing/Doing";
// import LandingHeroVideo from '../../components/ambassadors/LandingHeroVideo/LandingHeroVideo'

const Ambassadors = () => {
  const handleCTAClick = () =>
    window.open(
      "https://airtable.com/appXDqkHbjSrk9c7g/pagLxU9pUGpo7cuO7/form",
      "_blank"
    );
  return (
    <NonProtectedLayout>
      {/* <LandingHeroVideo src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/Hero_Header-Image.webp" onClick={() => null}  /> */}
      <div className="AmbassadorsContainer">
        <LandingHeroImage
          alt="Ambassador Hero Image"
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/Hero_Header-Image.webp"
          heading="JOIN THE TAARA QUEST MOVEMENT"
          ctaText="Apply Now"
          onClick={handleCTAClick}
        />

        <div className="SomethingBigger">
          <h3 className="heading">Become part of something bigger.</h3>
          <p className="description">
            As a Taara Ambassador, you are not just spreading the word; you're
            shaping the future. Help us develop and test the products women
            truly need.
          </p>
          <div className="WaitListButton" onClick={() => {}}>
            Join the Waitlist
          </div>
        </div>
        <Support />
        <EventCards />
        <Doing />
        <TaaraAmbassador />
        <PromiseYouEverything />
        <HowToJoin />
        <OurAmbassadors />
        <LandingHeroImage
          alt="Discover taara quest game"
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/assessment/Game+Signup+CTA+Image_Bottom+Page.png"
          heading="DISCOVER TAARA QUEST GAME"
          ctaText="join the waitlist"
          onClick={handleCTAClick}
        />
      </div>
    </NonProtectedLayout>
  );
};

export default Ambassadors;
