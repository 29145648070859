import React from "react";
import "./TestimonialCard.css";
import LazyImage from "../LazyImage/LazyImage";

const TestimonialCard = ({ profile }) => {
  return (
    <div className="profileCard">
      <div className="leftProfileCard">
        <LazyImage src={profile.avatar} alt={profile.name} bg="none" />
        {/* <img src={profile.avatar} alt={profile.name} loading="lazy" /> */}
      </div>
      <div className="rightProfileCard">
        <div className="profileCardTitle">{profile.name}</div>
        <div className="profileCardDescription">{profile.text}</div>
        <div className="profileCardDesignation">{profile.designation}</div>
      </div>
    </div>
  );
};

export default TestimonialCard;
